.snFooterContainer{
  background-color: #000000;;
  border-top: 5px solid $primary-color;
  padding-bottom: 20px;
  li.se-footer-logo { display: none; }
}
.custom-site-footer{
  display: grid;
  width: 100%;
  max-width: $content-area-width;
  margin: auto;
  grid-template-areas: "one two three four five six";
  grid-template-columns: 185px 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: $content-area-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    grid-column-gap: $content-area-padding-mobile;
  }
  padding: 60px $content-area-padding;
  // padding: $content-area-padding;
  // grid-template-rows: 1fr 1fr;
  .custom-footer-col-1{ grid-area: one; }
  .custom-footer-col-2{ grid-area: two; }
  .custom-footer-col-3{ grid-area: three; }
  .custom-footer-col-4{ grid-area: four; }
  .custom-footer-col-5{ grid-area: five; }
  .custom-footer-col-6{ grid-area: six; }
}
.custom-footer-col-2,
.custom-footer-col-3,
.custom-footer-col-4,
.custom-footer-col-5,
.custom-footer-col-6 {
  padding-top: 20px;
}

.custom-site-footer{
  h3{
    color: white;
    font-family: $font-tertiary;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .text{
    font-size: 12px;
    font-family: $font-secondary;
    color: white;
    ul{
      list-style: none;
      // column-count: 2;
      margin: 0;
    }
    a{
      color: white;
      &:hover{
        color: $primary-color;
      }
    }
  }

}


.custom-site-footer{
  @media screen and (max-width: $breakpoint-sm-max){
    display: flex;
    flex-flow: column;
    text-align: center;
    padding: 60px;
    h3{
      text-align: center !important;
    }
    .footer-links{ display: none; }
    .custom-footer-logo{
      max-width: 325px;
      width: 100%;
      margin: auto auto 30px auto;
    }
    .custom-footer-col-2, .custom-footer-col-3,
    .custom-footer-col-4, .custom-footer-col-5,
    .custom-footer-col-6 {
      display: none;
    }
  }
  .mobile-footer-links {
    margin-top: 40px;
    h3 {
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: .5rem;
    }
  }
}
@media all and (-ms-high-contrast:none), (-ms-high-contrast:active) {
  .custom-site-footer{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }
}
