.custom-visually-hidden{
  @include visually-hidden;
}

.padding {
  $padding-desktop: 25px;
  $padding-mobile: 13px;
  padding-top: $padding-desktop;
  padding-bottom: $padding-desktop;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $padding-mobile;
    padding-bottom: $padding-mobile;
  }
  &-top {
    padding-top: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $padding-mobile;
      }
    }
  }
  &-bottom {
    padding-bottom: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-bottom: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $padding-mobile;
      }
    }
  }
}
.column-gutter-none{
  &.layoutContainer,
  > .column{
    padding-left: 0;
    padding-right: 0;
  }
}
.compact {
  &.pageEl .pageElement,
  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
  &-top {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-top: 0;
    }
  }
  &-bottom {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}

.nike-banner {
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $breakpoint-sm-max){
    // padding:0 calc(100%/8);
  }
  @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){

  }
  img {
    height: auto;
    width: 35px;
      @media screen and (max-width: $breakpoint-sm-max){
        height: auto;
        width: 20px;
      }
  }
  p {
    font-family: "Exo", sans-serif;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    color: #000000;
    margin:0;
    margin-left:20px;
    @media screen and (max-width: $breakpoint-sm-max){
      font-size: 12px;
      margin-left: 5px;
    }
    @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
      font-size: 20px;
    }

  }
}
.ad-wrapper:first-of-type{
  padding:0px;
}

.presented-banner {
  .has-main-nav & {
    display: none;
  }
  .has-mobile-nav & {
    width: 100%;
    background-color: black;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
  }
  img {
    max-height: 20px;
    width: auto;

  }
  &:before {
    content: 'Powered By';
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 40px;
    width: auto;
    color: white;
    margin-right: 10px;
  }
}
