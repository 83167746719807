// .theme-game-list-widget {
//
//   // double class required for specificity
//   .statTable.sn-table-game-list {
//
//     $team-slot-height: 30px;
//     $team-slot-spacing: 2px;
//     $score-width: 4em;
//
//     // th, td:not(.away-team-score):not(.home-team-score) { text-align: left; }
//
//     .date-time,
//     .game-id-game-number {
//       font-size: 11px;
//       text-transform: uppercase;
//     }
//
//     .time { text-transform: lowercase; }
//
//     .team-logo {
//       display: none;
//       width: 20px;
//       height: 20px;
//       margin: -11px 5px -9px 0;
//       vertical-align: middle;
//     }
//
//     .status a {
//       // @include link-with-arrow;
//       font-size: 15px!important;
//       position: relative;
//       display: block;
//       padding-right: 1em;
//
//       &:after {
//         position: absolute;
//         right: 0;
//         display: none;
//       }
//     }
//
//     // TABLE LAYOUT STYLES
//
//     @media only screen and (min-width: $maxWidth) {
//
//       .layout-100 & {
//         table-layout: fixed;
//
//         td {
//           word-wrap: normal;
//           white-space: nowrap;
//           overflow: hidden;
//           text-overflow: ellipsis;
//           border: none!important;
//         }
//         tr {
//           border:none!important;
//         }
//
//         .away-team-score,
//         .home-team-score { width: 4em; }
//         .date-time-header { width: 14.5em; }
//         .game-id-game-number-header { width: 6em; }
//         .status-header { width: 10em; }
//
//         // make game number ellipsis from left (since right chars are most important)
//         .game-id-game-number {
//           white-space: normal;
//
//           > * {
//             height: 1.5em;
//             display: inline-block;
//             vertical-align: middle;
//             word-wrap: normal;
//             overflow: hidden;
//             text-align: right;
//             text-overflow: clip;
//             direction: rtl;
//             max-width: 100%;
//
//             &:after {
//               content: "…";
//               position: relative;
//               top: -1.75em;
//               display: inline-block;
//               background: #EEE;
//               width: 1em;
//               height: 1.7em;
//               line-height: 2;
//               left: -100%;
//               margin-right: -.99em; // trick FF into wrapping this
//               margin-top: -.5em;
//               margin-bottom: -.5em;
//               vertical-align: middle;
//             }
//           }
//         }
//       }
//     }
//
//
//     // COMAPCT LIST STYLES
//     // 100% width columns at desktop width are the only ones that can support
//     // the table layout. Everything else gets the compact list style.
//
//     @mixin compact-list {
//       display: block;
//
//       // override stat table adjustnments for outer table spacing
//       width: 100%;
//       margin-left: 0;
//       margin-right: 0;
//
//       thead { display: none; }
//       tbody { display: block; }
//       td { display: inline-block; }
//
//       tr {
//         font-size: 11px;
//         position: relative;
//         overflow: hidden;
//         display: block;
//         padding-top: $team-slot-height * 2 + $team-slot-spacing * 3 + 6px;
//         padding-left: 12px;
//         padding-right: 12px;
//         padding-bottom: 6px;
//
//         &:not(:last-child) {
//           @include line-pattern-border($pos:bottom);
//           margin-bottom: 12px - $team-slot-spacing;
//         }
//       }
//
//       td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score) {
//         padding: 0;
//         background: none;
//         height: auto;
//         font-size: 11px;
//         text-transform: uppercase;
//
//         &:not(.status) { margin-right: 6px; }
//       }
//
//       .away-team-name,
//       .home-team-name,
//       .away-team-score,
//       .home-team-score {
//         position: absolute;
//         top: $team-slot-spacing;
//         left: 0;
//         padding-top: 0;
//         padding-bottom: 0;
//         line-height: $team-slot-height;
//         width: calc(100% - #{$score-width} - #{$team-slot-spacing});
//       }
//
//       .home-team-name,
//       .home-team-score { top: $team-slot-height + $team-slot-spacing * 2; }
//
//       .away-team-score,
//       .home-team-score {
//         width: $score-width;
//         // left: auto;
//         right: 0;
//       }
//
//       .game-id-game-number > *:before { content: "Game ID: "; }
//
//       .status {
//         float: right;
//         margin-top: 1px;
//
//         a { font-size: 11px; }
//       }
//     }
//
//     .col-md-3 &,
//     .col-md-4 &,
//     .col-md-6 &,
//     .col-md-8 & { @include compact-list; }
//
//     @media only screen and (max-width: $maxWidth - 1) {
//       .col-md-12 & { @include compact-list; }
//     }
//   }
// }
//
// // Tourney game list element
// .gameListElement .sn-table-game-list {
//   white-space: nowrap;
//
//  .status a {
//     padding-right: 0;
//
//     &:after { content: ''; }
//   }
//
//   // column widths
//   .away-team-score > div,
//   .home-team-score > div { min-width: 3em; }
//   .away-team-name > div,
//   .home-team-name > div { min-width: 14em; }
//   .venue-subvenue > div { min-width: 12em; }
//   .game-id-game-number > div { min-width: 4em; }
//   .status > div { min-width: 6em; }
// }
// .theme-game-list-widget {
//
//   td.away-team-score,
//   td.home-team-score {
//     font-size: 23px;
//     font-family: Oswald, sans-serif;
//     font-weight: 700;
//     color: $grey-medium-x;
//     .winner & {
//       color: $primary-color;
//     }
//   }
//
//   tr.odd, tr.even {
//     // display: flex!important;
//     // flex-direction: column-reverse!important;
//     // align-items: flex-end!important;
//   }
//   .date-time, .venue-subvenue {
//     display: flex;
//     flex-direction: row;
//   }
//   .status, .date-time, .venue-subvenue {
//     display: flex;
//     flex-direction: column;
//   }
// }
// .theme-game-list-widget .statTable.sn-table-game-list td:not(.away-team-name):not(.home-team-name):not(.away-team-score):not(.home-team-score) {
//   border:none;
// }
// .col-md-8 .theme-game-list-widget .statTable.sn-table-game-list {
//   display: flex!important;
// }

.theme-game-list-widget {
  border-left:1px solid $grey-light;
  border-right:1px solid $grey-light;

  .statTable tbody td {
    background: transparent;
    padding:2px 2px;
  }
  .statTable td:last-child {
    border-right: none;
  }
  .statTable tr:last-child td {
    border: none;
  }
  thead, .team-logo {
    display: none;
  }
  td {
    border: none;
  }
  .odd, .even {
    padding:20px;
    display: grid;
    // grid-template-columns: 1fr 2fr 3fr;
    grid-template-rows: auto;
    border-top: 1px solid $grey-light;
  }
  .odd {
    background: $grey-light-x;
  }
  .even {
    background: #ffffff;
  }
  .away-team-score, .home-team-score {
    font-size: 23px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    color: $grey-medium;
    display: flex;
    align-items: center;
  }
  .away-team-name, .home-team-name {
    font-size: 16px;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    color:$grey-dark;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media screen and (max-width: $breakpoint-sm-max){
      font-size: 12px;
      font-weight: 600;
    }
    @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:1024px){
      font-size: 12px;
      font-weight: 600;
    }
  }
  tr {
    .date-time {
      grid-column-start: 4;
      grid-column-end:5;
      grid-row-start: 3;
      grid-row-end: 5;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $grey-dark;
      font-family: "Lato", sans-serif;
      font-weight: normal;
      font-size: 14px;
      @media screen and (max-width: $breakpoint-sm-max){
        font-size: 11px;
      }
        @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
          font-size: 11px;
        }

      &:after {
        content:'|';
        display: block;
        color:$grey-dark;
        margin-left: 2px;
        @media screen and (max-width: $breakpoint-sm-max){
          display: none;
        }
          @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
            display: none;
          }
      }
      .date-time-separator, .time {
        // display: none;
      }

    }
    .venue-subvenue {
      grid-column-start: 5;
      grid-column-end:6;
      grid-row-start: 3;
      grid-row-end: 5;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a {
        color: $grey-dark;
        font-family: "Lato", sans-serif;
        font-weight: normal;
        font-size: 14px;
        @media screen and (max-width: $breakpoint-sm-max){
                display: none;
        }
        @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
          display: none;
        }
      }

    }
    .away-team-name {
      grid-column-start: 2;
      grid-column-end:3;
      grid-row-start: 1;
      grid-row-end: 3;
      display: flex;
      justify-content: flex-start;

    }
    .home-team-name {
      grid-column-start: 2;
      grid-column-end:3;
      grid-row-start: 3;
      grid-row-end: 5;
      display: flex;
      justify-content: flex-start;

    }
    .away-team-score {
      grid-column-start: 1;
      grid-column-end:2;
      grid-row-start: 1;
      grid-row-end: 3;

    }
    .home-team-score {
      grid-column-start: 1;
      grid-column-end:2;
      grid-row-start: 3;
      grid-row-end: 5;

    }
    .status {
      grid-column-start: 3;
      grid-column-end:6;
      grid-row-start: 1;
      grid-row-end: 3;
      font-size: 15px;
      text-transform: uppercase;
      color:#000000;
      font-family: "Oswald", sans-serif;
    }
  }
  .extendedOptions {
    display: flex;
    justify-content: flex-start;
    padding:10px 20px;
    margin:0;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    .goToLink {
      display: flex;
      align-items: center;
      &:hover {
        color: $primary-color;
      }
    }
    .goToLink:before {
      content: '\f178';
      font-family: FontAwesome;
      color: $primary-color;
      display: flex;
      align-items: center;
      font-size: 14px;
      }
    }
}
.widget-header {
  .textBlockElement {
    margin-bottom: 5px;
    h3 {
      @include special-header;
      padding:10px 20px 0px 0px;
      margin-bottom: 0;
      span {
        font-size: 32px;
      }
    }
  }
}
