// Network Toggle
.custom-network-toggle{
  margin-left: 15px;
  font-size: 14px;
  font-family: $font-tertiary;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  background-color: $primary-color-dark;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color $transition-linear;
  &:hover{
    background-color: $primary-color-dark;
    .has-mobile-nav & {
      background-color: transparent;
    }

   }
  &:after{
    content: "\f0d7";
    font-family: FontAwesome;
    margin-left: 10px;
    font-size: 14px;
    line-height: 14px;
    transform: translateY(-.1em);
  }
  .has-mobile-nav & {
    background-color: transparent;
  }
}

// Network Toggle States
.custom-network-is-active{
  .custom-network-toggle{
    background-color: $primary-color-dark;
    .has-mobile-nav & {
      background-color: transparent;
    }
    &:after{ content: "\f0d8"; }
  }
}

// Network Menu
.custom-network-menu-container{
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;
  .custom-network-is-active &{
    display: block;
  }
  @media screen and (max-width: $breakpoint-sm-max){
  left:0;
  }
}

.custom-network-menu{
  width: 100%;
  max-width: $content-area-width;
  margin: auto;
  background-color: white;
  padding: 30px $content-area-padding $content-area-padding;
  border: 1px solid $grey-light;
  border-top: 0px solid transparent;
}
.custom-network-tabs{
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid $grey-light;
  margin-bottom: 15px;
}
.custom-network-tab-content{
  max-height: calc(70vh - #{$desktop-nav-height-no-subnav});
  overflow-y: scroll;
  transition: max-height $transition-linear;
  .nav-fixed &{
    max-height: calc(70vh - #{$desktop-nav-height-no-subnav-fixed});
  }
}
.custom-network-tab-header{
  color: $grey-dark;
  font-size: 16px;
  font-family: $font-primary;
  font-weight: bold;
  border-bottom: 5px solid transparent;
  padding: 15px 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: color $transition-linear, border-bottom-color $transition-linear;
  &.is-active,
  &:hover{
    color: $primary-color;
    border-bottom-color: $primary-color;
  }
}
.custom-network-tab-body{
  color: $grey-dark;
  list-style: none;
  column-count: 4;
  column-width: 25%;
  column-gap: 30px;
  break-inside: avoid;
  display: none;
  &.is-active{
    display: block;
  }
  li{
    margin-bottom: 1em;
  }
  .has-mobile-nav & {
    column-count: 2;
    column-gap: 20px;
  }
}

.has-mobile-nav .theme-main-nav .theme-nav-dropdown {
    display:  none;
}
.has-mobile-nav .theme-main-nav .theme-nav .theme-nav-item {
    display: none;
}

.has-mobile-nav .theme-main-nav {
    position: initial;
    margin-left: auto;
    display: block !important;
    box-shadow: none;
}

.has-mobile-nav .custom-nav-extra >div:not(.custom-network-toggle) {
    display: none;
}
