/* News Aggregator */
// MAKE LIQUID!!!!
// 1. Mixins
// 2. Global Styles
// 3. Expanded View
// 4. Condensed View
// 5. Comment Count and Read More

// 1. Mixins (Remove when liquid)
@mixin newsagg-header{
  // font-size: em(16);
  font-size: 22px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.35;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:1024px) {
      line-height: 1;
    }
  @media screen and (max-width: $breakpoint-sm-max){
    line-height: 1;
    font-size:18px;
  }
}
@mixin newsagg-image{
  display: flex;
  justify-content: center;
  float: left;
  padding: 3px 15px 7px 0px;
  img{
    width: auto;
    display: block;
  }
}
@mixin newsagg-date-author{
  color: #666;
  font-size: em(12);
  line-height: 1.25;
  display: inline-block;
  margin-right: .5em;
}
@mixin newsagg-teaser{
  font-family: $fontTwo;
  // font-size: em(14);
  font-sie:120%;
  font-weight: 500;
  text-transform: none;
  line-height: 1.25;
  color: $bodyCopyColorTwo;
  // margin: .75em 0;
}

// 2. Global styles
.newsAggregatorElement{
  .item{
    border-top: 1px solid $grey-light;
    border-left: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
    padding:20px;
   }
   .extendedOptions {
     border-bottom: 1px solid $grey-light;
     margin-top: 0px;
     padding:5px!important;
     .goToLink:before {
       display: none;
     }
     .rssLink:before {
       display: none;
     }
   }
  .dateAuthor{display: none;}
  .newsAuthor, .newsDate {
    @include newsagg-date-author;
  }
  .articleHasImage{
    > h4, .newsText, .commentsAndMore, .newsItemHeader{
      padding-left: 90px;
    }
    .newsItemHeader{
      position: relative;
      > a{
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
  }
}

//
.aggHeader{
  @include special-header;
}

// 3. Expanded Previews
.expanded{
  .newsItemHeader > a{
    @include newsagg-image;
  }
  .newsItemElement{
    h4{ @include newsagg-teaser; }
  }
  .newsItemHeader{
    h2 { @include newsagg-header; }
  }
  .newsText{
    clear: both;
    margin-top: 1em;
    h1, h2, h3, h4, h5, h6 {
      text-transform: none;
      font-weight: 600;
      font-size: 1em;
      letter-spacing: normal;
      margin: 0 0 .5em 0;
      padding: 0;
    }
    ul{
      list-style-type: disc;
    }
  }
}

// 4. Condensed Previews
.condensed{
  li {line-height: 1;}
  > a{
    .newsAggregatorElement &{ @include newsagg-image; }
    // width:150px;
    // height: 100px;
    // overflow: hidden;
    // padding: 0;
    //   img {
    //     width: auto;
    //     height: 100%;
    //     display: block;
    //
    //   }
  }
  .teaser{ @include newsagg-teaser; }
  .details, > ul {
    list-style-type: none;
    h4{ @include newsagg-header; }
  }
  .details{
    padding-left: 90px;
  }
}

// 5. Read more and comments
.commentCount {
  .button-commentCount {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1;
    &:hover{background: none;}
    .button-image-right {
      display: none;
    }
    .button-content {
      margin: 0;
      padding: 0;
      position: relative;
      border: none;
      background: none;
      color: $link-color;
      font-weight: 600;
      top: 2px;
      transition: all $transition-standard;
      &:hover {
        background: none;
        border: none;
        color: $hover-color;
      }
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        font-family: FontAwesome;
        line-height:1;
        content: "\f0e5";
        top: -1px;
        right: 0px;
        padding-right: .25em;
      }
    }
  }
}
.commentsAndMore{
  line-height: 1;
  margin: 5px 0 0 0;
  .readMore{
    // display: inline-block;
    display: none;
    margin-right: .5em;
    a{margin: 0;}
  }
}

// 7. Responsive
@include page-element-break(400){
  .newsAggregatorElement{
    .articleHasImage{
      > h4, .newsText, .commentsAndMore, .newsItemHeader{
        padding-left: 0;
      }
      .newsItemHeader{
        > a{
          position: relative;
          top: initial;
          left: initial;
        }
      }
    }
    .item > a {
      position: relative;
      top: initial;
      left: initial;
    }
    .condensed .details{
      padding-left: 0;
    }
  }
}

@include page-element-break(300){
  .newsAggregatorElement{
    .articleHasImage{
      > h4, .newsText, .commentsAndMore, .newsItemHeader{
        padding-left: 0;
      }
      .newsItemHeader{
        > a{
          position: relative;
          float: none;
        }
      }
    }
    .item > a {
      position: relative;
      float: none;
    }
    .condensed .details{
      padding-left: 0;
    }
  }
}

// Hoping to get rid of these gross mqs when we figure out our breakpoints and fully implement bootstrap
// Until then, these edge cases will have to be defined outside of the "page-element-break" mixin
@media only screen and (min-width: 769px) and (max-width: 999px){
  .layout-25-25-50,
  .layout-50-25-25{
    .col-md-3, .col-md-6{
      .newsAggregatorElement{
        .articleHasImage{
          > h4, .newsText, .commentsAndMore, .newsItemHeader{
            padding-left: 90px;
          }
          .newsItemHeader{
            > a{
              position: absolute;
              top: -2px;
              left: 0;
            }
          }
        }
        .condensed > a {
          display: inline-block;
          float: left;
          padding: 7px 15px 7px 0px;
        }
        .condensed .details{
          padding-left: 90px;
        }
      }
    }
  }
  .layout-25-50-25{
    .col-md-3, .col-md-6{
      .newsAggregatorElement{
        .articleHasImage{
          > h4, .newsText, .commentsAndMore, .newsItemHeader{
            padding-left: 0;
          }
          .newsItemHeader{
            > a{
              position: relative;
              display: block;
              float: none;
            }
          }
        }
        .condensed > a {
          display: block;
          float: none;
        }
        .condensed .details{
          padding-left: 0;
        }
      }
    }
  }
}

// CUSTOM NEWS
@mixin header-hover {
  h4 {
    a {
      color:$primary-color;
    }
  }
}
.user_mode {
  .hero-news {
    .newsSlideShow-headline {
      h1 {
        background-color: transparent;
        text-transform: uppercase;
        @media screen and (max-width: $breakpoint-sm-max){
          // padding:0 40px;
        }
        &:before {
          display: none;
        }
        a {
          line-height: 2;
          &:hover {
            color:$primary-color;
          }
            @media screen and (max-width: $breakpoint-sm-max){
              line-height: 1;
              position: relative;

            }
        }
      }
      p span {
        display: none;
      }
    }
    .newsSlideShow-more.button-small {
      display: none;
    }
    .newsSlideShow-thumbnails {
      background-color:transparent;
      box-shadow: none;
      justify-content: flex-start;
      bottom:40px;
      li {
        a {
          &:before {background-color: #fff;}
          &:hover,
          &.current {
            &:before {
              background-color: $primary-color;
              transform: scale(1.375,1.375);
            }
          }
        }
      }

    }
  }
  .featured-news {
    .newsAggregatorElement {
      border: none;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-around;
      @media screen and (max-width: $breakpoint-sm-max){
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

    .aggHeader {
      width: 100%;
      display:flex;
      flex-direction: column;
      align-items: center;
      color:#000;
      background: transparent;
      box-shadow: none;
      padding:0;
      margin: 0;
      &:after {
        content: '';
        display: block;
        width: 100px;
        height: 5px;
        background-color: $primary-color;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .item.condensed{
      grid-area: second;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      width: calc(100%/3);
      padding:0 10px;
      @media screen and (max-width: $breakpoint-sm-max){
        width: 100%;
      }
      &:hover {
        @include header-hover;
      }
    }

    .item.condensed {
      border:none;
        @media screen and (max-width: $breakpoint-sm-max){
          margin-bottom: 20px;
        }
      a {
        padding: 0;
        @media screen and (max-width: $breakpoint-sm-max){
          padding:0px;
        }
        img {
          width: 100%;
          height: 100%;
          // max-height: 173px;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height:194px;
        width: 100%;
        padding:20px;
        text-align: left;
        border: 1px solid $grey-light;
        @media screen and (max-width: $breakpoint-sm-max){
          margin-right: 0px;
          padding:10px 20px;
        }
        @media screen and (min-width: $breakpoint-intermediate-min) and (max-width: $breakpoint-intermediate-max) {
          padding:10px 20px;
        }
        h4 {
          a {
            text-transform: uppercase;
            color: #000;
            font-size: 22px;
            &:hover {
            color: $primary-color;
            }
            @media screen and (max-width: $breakpoint-sm-max){
              font-size: 18px;
            }
          }
        }
        .teaser {
          text-transform: none;
          color: $grey-medium-x;
          font-size: 14px;
        }
        .dateAuthor {
          display: none;
        }
      }
      .readMore {
        margin:0;
        display: block;
        .button-small {
          background: transparent;
          color: $grey-medium;
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          grid-gap: 10px;
          align-items: center;
          &:hover {
            color: $primary-color-dark;
          }
          &:after, &:before {
            display: block;
            content: '';
            height: 1px;
            width: 100%;
            background: $grey-medium;
          }
        }
      }

    }
    .extendedOptions {
      display: none;
    }

    }
  } //end featured-news

  .news-feed {
    .newsAggregatorElement {
      .item {
        padding:20px;
        &:hover {
          @include header-hover;
        }
      }
    }
    .condensed{
      li {line-height: 1;}
      > a{
        .newsAggregatorElement &{
          float: left;
        }
        width:150px;
        height: 100px;
        overflow: hidden;
        padding: 0;
        @media screen and (max-width: $breakpoint-sm-max){
          width:100%;
          height: auto;
        }
          img {
            width: auto;
            height: 100%;
            display: block;
          }
      }
      .details {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        @media screen and (max-width: $breakpoint-sm-max){
        padding: 10px 0px 10px 20px;
      }
        h4 {
          margin-bottom: 10px;
        }
       .teaser {
         color: #666;
       }
      }
      .extendedOptions {

      }
    }
    &.hide-desc {
      .newsAggregatorElement {
        .condensed {
          .teaser {
              display: none;
          }
        }
      }
    }
  }//end news-feed

  .news-video {
    .newsAggregatorElement {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-around;
      @media screen and (max-width: $breakpoint-sm-max){
        display: flex;
        flex-direction: column;
      }
      .condensed{
        ul {
          width: 100%;
        }
        li {line-height: 1;}
        > a{
          &:after {
            content:"";
            background-image: url('../images/play.svg');
            width: 80px;
            height: 80px;
            display: block;
            visibility: visible;
            position: absolute;
            transform: translate(140%, 50%);
            @media screen and (max-width: 320px){
                transform: translate(120%, 50%)!important;
            }
            @media screen and (max-width: 375px){
                transform: translate(150%, 50%);
            }
              @media screen and (max-width: 415px){
                transform: translate(175%, 50%);
              }
            @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max) {
              transform: translate(75%, 35%);
            }
          }
          .newsAggregatorElement &{
            float: left;
          }
          // max-height: 173px;
          height: 100%;
          width: 100%;
          overflow: hidden;
          padding: 0;
          @media screen and (max-width: $breakpoint-sm-max){
            width: 100%;
            max-height: 173px;
            // height: 100%;
          }
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
        }
        .details {
          padding-left: 20px;
          border: 1px solid $grey-light;
          height: 150px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 100%;
          @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
          padding: 10px 0 10px 20px;
          height: 100px;
          }
          @media screen and (min-width: 1024px) and (max-width:1366px){
            padding:10px 0 10px 20px;
          }
          h4 {
            line-height: 1;
            flex-basis: 100%;
            flex-wrap:wrap;
            @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
              font-size: 18px;


            }
          }
          .teaser {
            display: none;
          }
        }
      }
      .aggHeader {
          width: 100%;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding:0;
          margin-bottom: 20px;
      }
      .item {
        padding:0;
        border:none;
        margin: 0 0 30px 0;
        width: 30%;
        &:hover {
          @include header-hover;
        }
        @media screen and (max-width: $breakpoint-sm-max){
          width: 100%;
        }

      }
      .item.condensed {
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .extendedOptions {
        display: none;
      }
    }
  }
}// end user_mode
.header {
  .pageElement {
    margin-bottom: 0px;
  }
  .textBlockElement {
    h3 {margin-bottom: 0;}

  }
  h3 {
    display: inline-block;
    width: auto;
    max-width: calc(100% - 9px); // Ensures that the box shadow shows at small sizes
    height: auto;
    padding: 10px 20px 20px 0px;
    color: #000;
    background: transparent;
    font-size: 32px;
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 5px;
      background-color: $primary-color;
      margin-top: 10px;
    }
  }
}

// INTERNAL NEWS
.user_mode,.newsPage {
  .newsItemElement {
    .newsArticleThumbnail {
      display: none;
    }
    .teaserText {
      display: none;
    }
    hr {
      height: 1px;
      background: $grey-light;
    }
  }
  .newsTags {
    display: none;
  }
  .bottomOptions {
    display: none;
  }
}
.block-quote {
  .textBlockElement {
      box-shadow: -5px 0px 0px $primary-color;
    .text {
      p {
        font-size: 206%;
        font-weight: 700;
        color: #000;
        display: inline-block;
        line-height: 1.6em;
        font-family: Oswald, sans-serif;
        margin-left: 23px;
        padding:35px 0 10px 0px;
        &:after {
          content: '\f10e';
          font-family: 'fontawesome';
          color: $primary-color;
          display: inline-block;
          margin-left: 15px;
          position: relative;
          bottom: 15px;
        }
        &:before {
          content: '\f10d';
          font-family: 'fontawesome';
          color: $primary-color;
          display: inline-block;
          margin-right:15px;
          position: relative;
          bottom: 15px;
        }
      }
    }
  }
}
.internal-news {
  .newsAggregatorElement {
    .item {
      padding:0;
      a {
        img {
          display: none;
        }
      }
      .details {
        padding:20px;
        h4 {
          a {
            font-size: 22px;
            font-family: Oswald, sans-serif;
          }
        }
        .dateAuthor {
          display:block!important;
        }
        .teaser {
          display: none;
        }
      }
    }
    .extendedOptions {
      display: none;
    }
  }
}

@mixin clearfix{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}
@function aspect-to-percent($target, $container){
  @return ($container / $target) * 100%;
}

$agg-news-element-entry-preview-image-width: 100%;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(2,1);
$agg-news-element-entry-preview-image-margin: 0 10px 10px 0;
$agg-news-element-entry-preview-image-float: true;

.newsAggregatorElement{
  .featured-news &, .news-video & {
  // ENTRY PREVIEW IMAGE
  .user_mode & {
  .condensed > a{
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    // margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }
}
}
}

// .newsAggregatorElement{
//
//   .news-feed & {
//   // ENTRY PREVIEW IMAGE
//   .condensed > a{
//     @include clearfix;
//     display: block;
//     position: relative;
//     width: 150px;
//     height:100px;
//     max-width: $agg-news-element-entry-preview-image-width;
//     margin: $agg-news-element-entry-preview-image-margin;
//     @if $agg-news-element-entry-preview-image-float == true{ float: left; }
//     &:before{
//       content: "";
//       float: left;
//       padding-bottom: 150px/100px * 100%;
//     }
//     img{
//       position: absolute;
//       width: 100% !important;
//       height: 100% !important;
//       object-fit: cover;
//       object-position: center;
//     }
//   }
// }
// }
@media all and (-ms-high-contrast:none), (-ms-high-contrast:active) {
  .news-video {
    .newsAggregatorElement {
      .details {
        width: 100%;
        h4 {
          font-size: 16px;
          width: 100%;
          text-align: center;
        }
      }
    }


  }
}
