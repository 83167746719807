/* Widget - Stat Leader
---------------------------------------------------------------------------------------------------- */
.widget-leader {
  .sportTableLabel { @include sport-table-label; }

  .statTable {
    width: 100%;

    th, td {
      &:nth-child(1) { text-align: center; } // rank column
      &:nth-child(2) { text-align: left; } // name column
      &:nth-child(3) { text-align: center; } // stat column
    }

    th:nth-child(1) { width: 30px; }

    td {
      white-space: nowrap;

      &:nth-child(1) { font-weight: 700; }
    }
  }

  .leaderName {
    display: inline-block;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .leader-highlight {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    background-color: $grey-light-x;
  }

  .stats {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 0 0 10px;
    text-align: center;
  }
  .stats-major:after{
    display: none;
  }


  .stats-major,
  .stats-minor {
    margin: 0;
    padding: 0;

  }

  .player_photo {
    overflow: hidden;
    height: 70px;
    // border: 3px solid $link-color;
    border: none;
    background-color: #C7C7C7; // Same grey as default player photo

    img {
      display: none;
      width: 70px;
    }
  }
}


  .lastLi {
    // margin: 0px 20px;
    .linkText {
      font-size: 16px;
      color: $grey-medium-x;
    }
    .leader-highlight {
      border:1px solid $grey-light;
      // margin:0 20px;
      .stats-major {
        font-size: 36px;
        font-weight: 700;
        font-family: "Oswald", sans-serif;
        color: #000000;
        line-height: 1;
      }
      .stats-minor {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        a {
          color: $primary-color;
        }
      }
    }
    .statTable {
      margin-bottom:20px;
      thead {
      }
      & th:nth-child(1), & th:nth-child(2), & th:last-child {
      border-left:none;
      border-right:none;
      background: #ffffff;
      }
    }
  }
  .player-stat-widget {
    border:1px solid $grey-light;

  }
