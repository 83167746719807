/* Call to Action Element */
// 1. Default Styles
// 2. Variations
// 3. Edge Case Fixes
// 4. Fix for tall text in short container
// 5. Adjustments for Small Columns & Mobile Views
// 6. Accomidation for touch screens

// 1. Default Styles
.sn-call-to-action,
.sn-call-to-action * {
  box-sizing: border-box;
}
.sn-call-to-action {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding:7px 0 5px 0;
 &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    display:none;
    width:100%;
    height:5px;
    background:$accent-color;
    border-bottom:2px solid #fff;
    z-index:10;
  }
  &:after {
    display: none;
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:3px;
    border-top:2px solid #fff;
    z-index:10;
    @include line-pattern(#aaa,#fff);
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
  .sn-call-to-action-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    text-align: center;
  }
  .sn-call-to-action-overlay-inner {
    display: table;
    width: 100%;
    height: 100%;
  }
  .sn-call-to-action-overlay-text {
    display: table-cell;
    font-size: rem(10);
    height: 100%;
    line-height: 1;
    vertical-align: middle;
  }
  .sn-call-to-action-title {
    color: #fff;
    font-family:$fontOne;
    font-size: rem(24);
    font-weight:700;
    margin-bottom: 0.3em;
  }
  .sn-call-to-action-subtitle {
    color: #fff;
    font-family:$fontTwo;
    font-size: rem(16);
    font-weight: 400;
    line-height: 130%;
    margin-bottom:0;
    a,
    span {
      margin: 0 .25em;
      color: #fff;
      display: inline-block;
    }
  }
}
.sn-call-to-action .sn-call-to-action-subtitle:first-of-type > * {
  // padding-top: 5px;
}

// 2. Variations

/* Slide Up */
.sn-call-to-action.sn-variation-slide-up {
  .sn-call-to-action-overlay {
    background: $link-color_opacity;
    top: auto;
    height: 0;
    transition: height 0.5s ease;
  }
  .sn-call-to-action-title {
    margin-bottom: 20px;
  }
  .sn-call-to-action-subtitle {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay {
      height: 100%;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
    }
  }
}

/* Zoom */
.sn-call-to-action.sn-variation-zoom {
  img {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

/* Text Zoom */
.sn-call-to-action.sn-variation-text-zoom {
  .sn-call-to-action-overlay-text {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay-text {
      transform: scale(1.1);
    }
  }
}

/* Border */
.sn-call-to-action.sn-variation-border {
  .sn-call-to-action-overlay {
    box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
    transition: box-shadow 0.5s ease-in;
  }
  &:hover {
    .sn-call-to-action-overlay {
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
}

// 3. Edge Case Fixes

/* mirrored CTA preview img alignment fixes */
.pe25 .elementPreview img,
.pe33 .elementPreview img {
  width: 100%;
  margin: 0px;
}

// 4. Fix for tall text in short container
.sn-call-to-action.sn-variation-tall-text {
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto!important;
    max-width: initial;
    min-width: 100%;
    transform: translate(-50%, -50%);
  }
}
.sn-call-to-action.sn-variation-zoom.sn-variation-tall-text {
  &:hover {
    img {
      transform: scale(1.2) translate(-40%, -40%);
    }
  }
}

// 5. Adjustments for Small Columns & Mobile Views

/* paragraph spacing */
.pe25 .sn-call-to-action-overlay-text p {
  margin-bottom: .15em;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .col-md-4 .sn-call-to-action, .col-md-3 .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}
@media only screen and (max-width: 350px) {
  .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}

// 6. Accomodation for touch screens
.touch-screen {
  .sn-call-to-action.sn-variation-slide-up,
  .sn-call-to-action.sn-variation-slide-up:focus,
  .sn-call-to-action.sn-variation-slide-up:hover{
    .sn-call-to-action-overlay {
      height: 100%;
      transition: none;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
      transition: none;
    }
  }
  .sn-call-to-action.sn-variation-border,
  .sn-call-to-action.sn-variation-border:focus,
  .sn-call-to-action.sn-variation-border:hover{
    .sn-call-to-action-overlay{
      transition: none;
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
  .sn-call-to-action.sn-variation-text-zoom,
  .sn-call-to-action.sn-variation-text-zoom:focus,
  .sn-call-to-action.sn-variation-text-zoom:hover{
    .sn-call-to-action-overlay-text{
      transition: none;
      transform: scale(1);
    }
  }
}

// CUSTOM CTAS

$o-red:rgba(193,22,22,.7);
@mixin custom-cta {
  .sn-call-to-action:before,
  .sn-call-to-action:after {
    display: none;
  }
  .sn-call-to-action .sn-call-to-action-title {
    margin-bottom: 0;
  }
}

// .custom-cta {
//   @include custom-cta;
//   .sn-call-to-action .sn-call-to-action-overlay {
//     background: rgba(0,0,0,.2);
//     background-blend-mode: multiply;
//     outline: 1px solid $o-red;
//     outline-offset: -15px;
//     &:before {
//       content:'';
//       position: absolute;
//       right: 15px;
//       bottom: 15px;
//       width: 0;
//       height: 0;
//       border-top: 30px solid transparent;
//       border-right: 30px solid $o-red;
//       border-bottom: 30px solid $o-red;
//       border-left: 30px solid transparent;
//       top: initial;
//       left: initial;
//       background: none;
//     }
//   }
//   .sn-call-to-action .sn-call-to-action-title {
//     &:after {
//       content: '';
//       display: block;
//       height: 5px;
//       width: 50px;
//       background-color: $primary-color;
//       left: 50%;
//       transform: translateX(-50%);
//       position: relative;
//       margin:15px 0 10px 0;
//     }
//   }
//   .sn-call-to-action .sn-call-to-action-subtitle {
//     a:hover{
//       text-decoration: underline;
//     }
//   }
// }

.custom-cta-2 {
  .pageElement {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  @include custom-cta;
  .sn-call-to-action.sn-variation-default.sn-variation-tall-text {
    height: 130px!important;
  }
  .sn-call-to-action-overlay {
    border:1px solid $grey-light;
  }
  .sn-call-to-action .sn-call-to-action-title {
    font-size: 400%;
    line-height: 1;
    @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
      font-size: 200%;
    }
      @media screen and (max-width: $breakpoint-sm-max){
        font-size: 350%;
      }
  }
  .sn-call-to-action .sn-call-to-action-overlay-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid $grey-light;
    .sn-call-to-action-title {
      order:2;
    }
    .sn-call-to-action-subtitle {
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      span {
        margin:0;
      }
    }
    .sn-call-to-action-subtitle:nth-of-type(1) {
      order:1;
    }
    .sn-call-to-action-subtitle:nth-of-type(2) {
      order:3;
      margin-bottom: 10px;
      span {
        display:block!important;
        position: absolute!important;
      }
        &:after {
          content: '';
          display: block;
          width: 50px;
          height: 5px;
          background: $primary-color;
          position: relative;
          top: 21px;
          transition: width .2s linear;
          @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
            top:31px;
          }
        }

    }
  }
} //end custom-cta-2
  .custom-cta-2:hover {
    .sn-call-to-action:hover {
      .sn-call-to-action-subtitle:nth-of-type(2):after {
        width: 100px;
        transition: width .2s linear;
      }
  }
}

.custom-cta {
  .textBlockElement {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin-bottom: 0;
      position: absolute;
      top: 18%;
      color: white;
      font-size: 24px;
      font-weight: 700;
      font-family: "Oswald", sans-serif;
      z-index: 2;
        @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
          font-size: 18px;
          top:10%;
        }
      &:after {
        content: '';
        display: block;
        height: 5px;
        width: 50px;
        background-color: $primary-color;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        margin:15px 0 10px 0;
        z-index: 2;
        @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
          margin:5px 0 0 0;
          height: 3px;
        }
        @media screen and (min-width: $breakpoint-intermediate-max) and (max-width:1366px){
        margin:5px 0 0 0;
        }

      }
    }
    img {
      max-width: inherit;

    }
  .text {
    position: absolute;
    top: 44%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.3;
    color: white;
    z-index: 2;
    @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
    line-height: 1;
    font-size: 14px;
    top:35%;
    }
    @media screen and (min-width: $breakpoint-intermediate-max) and (max-width:1366px){
    top:36%;
    }

    p:first-of-type {
      margin-top: 10px;
    }
    p {
      margin-bottom: 0;
      a {
        color: white;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
          color: white;
        }
      }
    }
  }
  .leftTextImage, .originalTextImage {
    margin: 0;
    outline:1px solid $primary-color;
    outline-offset: -15px;
    @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
    outline-offset: -10px;
    }
    &:before {
      content:'';
      position: absolute;
      right: 15px;
      bottom: 15px;
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-right: 30px solid $o-red;
      border-bottom: 30px solid $o-red;
      border-left: 30px solid transparent;
      top: initial;
      left: initial;
      background: none;
      @media screen and (min-width: $breakpoint-intermediate-min) and (max-width:$breakpoint-intermediate-max){
        right: 10px;
        bottom: 10px;
        border-top: 15px solid transparent;
        border-right: 15px solid $o-red;
        border-bottom: 15px solid $o-red;
        border-left: 15px solid transparent;
      }
    }
    &:after {
      background: rgba(0,0,0,.2);
      background-blend-mode: multiply;
      content:'';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }
}
}
